import * as bootstrap from 'bootstrap';
import {Popover} from 'bootstrap';
import select2 from 'select2';
import $ from "jquery";
import Masonry from "masonry-layout";

require('readmore-js');

window.$ = $
window.jQuery = $
window.Masonry = Masonry;

window.loadingVimeo = function (els) {
    els.each(function (i, el) {
        var endpoint = 'https://vimeo.com/api/oembed.json';
        var vimeo_url = jQuery(el).data('videoSrc');
        var url = endpoint + '?url=' + encodeURIComponent(vimeo_url) + '&callback=vmThumbnail';
        jQuery.getScript(url);
    });
}

window.vmThumbnail = function (video) {
    let id = video.video_id;
    $('#video' + id).html(video.html);
    $('#video-thumbnail' + id).attr('src', video.thumbnail_url);
}

$(document).ready(function () {

    $('.readmore').readmore({
        'moreLink': '<a href="#" class="readmore-expand">' + Joomla.JText._("TPL_MST_READ_MORE", 'Read More') + '</a>',
        'lessLink': '<a href="#" class="readmore-collapse">' + Joomla.JText._("TPL_MST_READ_Less", 'Less') + '</a>',
        afterToggle: function (trigger, element, expanded) {
            // aninmation collapse
            if (!expanded) {
                // The "Close" link was clicked
                $('html, body').animate({
                    scrollTop: element.offset().top
                }, {
                    duration: 100
                });
            }
        },
    });

    if (document.querySelector('.envelope-popover')) {
        Array.from(document.querySelectorAll('.envelope-popover')).map((item) => {
            new Popover(item, {
                html: true,
                customClass: "share-tooltip",
                title: '',
                content: '<div class="d-flex align-items-center mb-2 pe-4">' +
                    '<div class="envelope-bg icon"><i class="fa-solid fa-envelope text-white"></i></div>' +
                    '<a href="' + $(item).data('url') + '" class="ps-1 fw-bold fs-6 text-decoration-none">' + Joomla.JText._('SHARE_EMAIL') + '</a></div>' +
                    '<div class="d-flex align-items-center pe-4">' +
                    // '<div class="link-bg icon"><i class="fa-solid fa-link text-white"></i></div>' +
                    // '<a href="#" class="ps-1 fw-bold fs-6 text-decoration-none click">' + Joomla.JText._('COPY_LINK') + '</a>' +
                    '</div>',
                placement: "bottom",
            })
        })
    }

    $('.envelope-popover').on('shown.bs.popover', function (e) {
        e.preventDefault();
        let link = $(this).data('link');
        var btn = document.getElementsByClassName("click")[0];
        btn.onclick = function (e) {
            e.preventDefault();
            debugger;
            try{
                navigator.clipboard.writeText(link);
            } catch(e) {
                Publivate.log(Joomla.JText._('CLIPBOARD_COPY', 'The clipboard  is not available.'));
                console.log('clipboard is not available', e);
            }

        };
        $('html').on('mouseup', function () {
            $('.envelope-popover').popover('hide');
        });
    });

    if (document.querySelector('.info-icon')) {
        new Popover('.info-icon', {
            trigger: 'hover',
            html: true,
            customClass: "info-tooltip",
            title: '',
            content: Joomla.JText._('ASK_US_HELP'),
            placement: "bottom",
        })
    }

    if (document.querySelector('.collab-icon')) {
        new Popover('.collab-icon', {
            trigger: 'hover',
            html: true,
            customClass: "info-tooltip",
            title: '',
            content: Joomla.JText._('COLLAB_HELP'),
            placement: "bottom",
        })
    }

    // Social media sharing popup
    if (document.querySelector('.social-share')) {
        Array.from(document.querySelectorAll('.social-share')).map((item) => {
            let _html = $($(item).data('target')).remove();

            let popup = new Popover(item, {
                html: true,
                customClass: "share-tooltip",
                title: '',
                content: function () {
                    _html.removeClass('hide');
                    return _html;
                },
                placement: "right",
            });

            let popover = new Popover(item, {
                html: true,
                customClass: "rating_box",
                title: '',
                trigger: 'hover',
                content: '<div  class="text-white">' + $(item).data('title') + '</div>',
                placement: "top",
            });

            // Allows for separate popover and popup dialog
            $(item).click(function (e) {
                popup.show();
                popover.hide();
            });

            // Remove social share dialog, close button
            _html.find('[data-toggle="social-share-hide"]').click(function () {
                popup.hide();
            });

            // Closes social share after click is clicked
            _html.find('a').click(function (e) {
                popup.hide();
            });
        })

    }


    // Comment's tools
    if (document.getElementsByClassName('comment-popover')) {

        $('.comment-popover').click(function (e) {
            let target = $(this).data('target');
            e.stopPropagation();
            // remove other popup
            $('.custom-popover.show').removeClass('show');
            $(target).toggleClass('show');
        });

        $('.comment-popover').each(function () {
            let target = $(this).data('target');
            // Close popup when click a link in it.
            $(target).find('a').click(function () {
                $(target).toggleClass('show');
            });
        });

        //adding this event for dismissing report abuse popover on askus page
        $('html').on('mouseup', function () {
            $('.custom-comment-popver.show').removeClass('show');
        });
    }

    if (document.getElementsByClassName('write-commnet-popover')) {
        Array.from(document.querySelectorAll('.write-commnet-popover')).map((item) => {
            let popover = new Popover(item, {
                html: true,
                customClass: "rating_box",
                title: '',
                trigger: 'hover',
                content: '<div  class="text-white">' + $(item).data('title') + '</div>',
                placement: "top",
            });

        })

        $('.write-commnet-popover').click(function () {
            $(this).parent().children('.write_your_comment_box').toggle();
        })
    }


    if (document.querySelector('.myIdeaModalForm,.myStoryModalForm')) {

        // Edit hover over
        Array.from(document.querySelectorAll('.list-idea .myIdeaModalForm')).map((item) => {
            if ($(item).data('title')) {
                let popover = new Popover(item, {
                    html: true,
                    customClass: "rating_box",
                    title: '',
                    trigger: 'hover',
                    content: '<div  class="text-white">' + $(item).data('title') + '</div>',
                    placement: "top",
                });
            }
        })

        $('.myIdeaModalForm,.myStoryModalForm').on('click', function (e) {
            let _target = $(this).data('bs-target');
            let _modal = $(_target);
            let _url = $(this).data('href');
            let _title = $(this).data('title');
            if (_title) {
                _modal.find('.modal-title').text(_title);
            }
            _modal.find('.success').addClass('d-none')
            _modal.find('iframe').attr('src', '').attr('src', _url).show();
            _modal.modal('show');
        });

        $('.add-idea-btn').click(function (e) {
            let _target = $(this).data('bs-target');
            let _modal = $(_target);
            let _url = $(this).data('href');
            let _title = $(this).data('title');
            if (_title) {
                _modal.find('.modal-title').text(_title);
            }
            _modal.find('.success').addClass('d-none')
            _modal.find('iframe').attr('src', '').attr('src', _url).show();
            _modal.modal('show');
        })
    }


    // show the abuse form
    if (document.querySelector('[data-bs-toggle="report-abuse-modal"]')) {
        $('[data-bs-toggle="report-abuse-modal"]').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-abuse mfp-fade p-3',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });

    }

    // Initial the popup for Idea's tools
    if (document.querySelector('.idea-tools-popover')) {

        // Close other shown popups
        function closePopover(e) {
            $('#idea-abuse.show, .custom-comment-popver.show').removeClass('show');
        }

        // Open/close popup
        $('.idea-tools-popover').click(function (e) {
            closePopover(e)
            e.stopPropagation();
            let target = $(this).data('target');
            $(target).toggleClass('show');
        });

        $('.idea-tools-popover').each(function () {
            let target = $(this).data('target');

            $(target).find('a').click(function () {
                $(target).toggleClass('show')
            });
        });

        $('html').click(e => {
            closePopover(e)
        });

    }


    if (document.querySelector('.ratings-popover')) {
        Array.from(document.querySelectorAll('.ratings-popover')).map((item) => {
            new Popover(item, {
                html: true,
                customClass: "rating_box",
                title: '',
                content: '<div class="text-white">' + $(item).data('title') + '</div>',
                placement: "top",
                trigger: 'hover',
            })
        })
    }


    if (document.querySelector('.improving-popover')) {
        Array.from(document.querySelectorAll('.improving-popover')).map((item) => {
            new Popover(item, {
                html: true,
                customClass: "rating_box",
                title: '',
                content: '<div class="text-white">' + $(item).data('title') + '</div>',
                placement: "top",
                trigger: 'hover',
            })
        })
    }

    // @deprecated - Replaced with social-share functionality
    // if (document.querySelector('.share-popover')) {
    //     Array.from(document.querySelectorAll('.share-popover')).map((item) => {
    //         new Popover(item, {
    //             html: true,
    //             customClass: "rating_box",
    //             title: '',
    //             content: '<div class="text-white">'+ $(item).data('title')  +'</div>',
    //             placement: "top",
    //             trigger: 'hover',
    //         })
    //     })
    // }

    if (document.querySelector('.unfollow-popover')) {
        Array.from(document.querySelectorAll('.unfollow-popover')).map((item) => {
            new Popover(item, {
                html: true,
                customClass: "rating_box",
                title: '',
                content: function () {
                    return '<div class="text-white">' + $(item).data('title') + '</div>';
                },
                placement: "top",
                trigger: 'hover',
            })
        })
    }

    if (document.querySelector('.follow-popover')) {
        Array.from(document.querySelectorAll('.follow-popover')).map((item) => {
            new Popover(item, {
                html: true,
                customClass: "rating_box",
                title: '',
                content: function () {
                    return '<div class="text-white">' + $(item).data('title') + '</div>';
                },
                placement: "top",
                trigger: 'hover',
            })
        })
    }


    if (document.querySelector('.profile-popup-main')) {
        Array.from(document.querySelectorAll('.profile-popup-main')).map((item) => {
            new Popover(item, {
                trigger: 'hover',
                html: true,
                customClass: "profile-popup",
                title: '',
                content: '<div class="text-center"><div class="yellow-cover"></div> <div class="profile-img postion-relative mt-3"> <img width="80" height="80" src="https://www.fillmurray.com/100/100" alt="alt text"> </div> <div class="p-3"> <h2 class="fs-5 fw-bold mb-0">Dwayne Smith</h2> <p class="mb-0">dwayne@demo.com</p><p class="mb-0">555-555-5555</p><p class="mb-0">Toronto, Canada</p></div></div>',
                placement: "bottom",
            })
        })
    }

    if (document.querySelector('.js-example-basic-single')) {
        $('.js-example-basic-single').select2();
    }

    if (document.getElementById("days")) {
        (function () {
            const second = 1000,
                minute = second * 60,
                hour = minute * 60,
                day = hour * 24;

            const endDate = document.getElementById('time-remaining').dataset.end;
            const dateString = new Date(endDate.replace(/-/g, "/"));
            const countDown = dateString.getTime(),
                x = setInterval(function () {
                    const now = new Date().getTime(),
                        distance = countDown - now;

                    if (distance < 0) {
                        return;
                    }

                    document.getElementById("days").innerText = Math.floor(distance / (day)),
                        document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour)),
                        document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute)),
                        document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);

                }, 0)
        }());
    }

    // this handles submit on modal forms; this should be more specific based
    // if (document.getElementsByClassName("submit")) {
    //     $("button.submit").click(function() {
    //
    //         $(".submit").hide();
    //         $(".success").removeClass("d-none");
    //     })
    //
    //     $(".return").click(function() {
    //         setTimeout(() => {
    //             $(".submit").show();
    //             $(".success").addClass("d-none");
    //         }, 800);
    //     })
    //
    // }

    if (document.getElementById("search_trigger")) {
        $("#search_trigger").click(function () {
            $(".search_box:not(.search_box_mobile").toggle();
        })
    }

    if (document.getElementById("search_trigger_mobile")) {
        $("#search_trigger_mobile").click(function () {
            var $navbar = $(".navbar-collapse");
            var _opened = $navbar.hasClass("show");
            if (_opened === true) {
                $navbar.collapse('hide');
            }

            $(".search_box.search_box_mobile").toggle();
        })
    }

    // Vimeo player container.
    if ($('.vimeo-container').length) {
        loadingVimeo($('.vimeo-container'));
    }

    /**
     * Update profile avatar form submission
     */
    $('.profile-detail-box').find('#upload_file_input').on('change', function () {

        if (this.files.length) {

            const $file = this.files[0], $this = $(this), $form = $('#member-profile');
            $this.prop('disabled', true);
            var myFormData = new FormData();
            myFormData.append('pictureFile', $file);

            $.ajax({
                type: "POST",
                url: "index.php?option=com_ajax&plugin=imageupload&format=raw",
                processData: false, // important
                contentType: false, // important
                //dataType : 'json',
                data: myFormData,
                success: function (response) {
                    var obj = JSON.parse(response);
                    if (obj.error) {
                        alert(obj.error);
                        $this.val('');
                        $this.prop('disabled', false);
                    } else if (obj.relpath) {
                        $('.profile-img').find('img').attr('src', obj.relpath);
                        $form.find("#setimgval").val(obj.relpath);
                        $form.submit();
                    }
                }, error: function (response) {
                    Publivate.alert(Joomla.JText._("UNABLE_TO_UPLOAD_WARNING"));
                }
            });
        }
    })

    // let surveys = $('.survey-iframe').toArray();
    // let surveyIframe = surveys.shift();
    //
    // if(surveyIframe){
    //     surveyIframe.onload = function(){
    //         console.log('LOGGGINGGGGG');
    //         surveyIframe.onload = null;
    //         surveys.forEach(function (el) {
    //             el = $(el);
    //             el.attr('src', el.data('src'));
    //             el.iFrameResize( { checkOrigin: false} );
    //         })
    //
    //     }
    //    $(surveyIframe).attr('src', $(surveyIframe).data('src'));
    //     $(surveyIframe).iFrameResize( { checkOrigin: false} );
    // }

    // Stops session and CSRF race conditions
    let surveys = $('.survey-iframe');
    let d = 1200, timeout = 0;
    surveys.each(function () {
        let self = $(this);

        setTimeout(function () {
            self.attr('src', self.data('src'));
            self.iFrameResize({
                heightCalculationMethod: "lowestElement",
                checkOrigin: false,
            });
        }, timeout);

        timeout += d;
    });

}); // End document ready

// This breaks other items in mobile, too global.
$(window).click(function (e) {
    var clickover = $(event.target);
    var $navbar = $(".navbar-collapse");
    var _opened = $navbar.hasClass("show");
    if (_opened === true && !clickover.hasClass("navbar-toggler")) {
        $navbar.collapse('hide');
    }
});

